<template>
  <div class="news-table">
    <div class="heading">
      <span class="title">
        {{ title }}
      </span>
      <hr class="line" />
    </div>
    <div v-for="(post, index) in news" :key="index">
      <NewsPost :post="post" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    news: { required: true, type: Array },
    title: { required: true, type: String },
  },

  components: {
    NewsPost: () => import("/components/News/NewsPost.vue"),
  },
};
</script>

<style lang="scss" scoped>
.news-table {
  .heading {
    display: flex;
    align-items: center;
    gap: 1.5rem;

    .title {
      color: var(--a-color-blue);
      white-space: nowrap;
    }

    .line {
      margin: 0;

      width: 100%;
      border-top: 1px solid var(--a-color-blue);
    }
  }
}
</style>
